import React from 'react';
import { connect, useSelector } from 'react-redux';
import * as styles from './intro.module.scss';
import { useIntl } from 'gatsby-plugin-react-intl';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { setLoginPopupOpen } from '@src/state/loginPopup/actions';
import { sendEvent } from '@src/utils/amplitude';
import arrowDown from '@src/assets/images/landing/arrow-down.svg';
import LoginPopup from '@components/molecules/LoginPopup';
import { mixPanelService } from '../../../../services/mixPanelService';

const Intro = ({ isLoginPopupOpen, setLoginPopupOpen }) => {
	const account = useSelector((state) => state.authReducer);
	const intl = useIntl();

	return (
		<section className={[styles.container, 'container-v'].join(' ')}>
			<div className={styles.content}>
				<div className={styles.text}>
					<h1 className={[styles.title, styles[intl.locale]].join(' ')}>
						{intl.formatMessage({ id: 'videolanding.intro.title' })}
					</h1>
					<p className={styles.subtitle}>{intl.formatMessage({ id: 'videolanding.intro.subtitle' })}</p>
				</div>
			</div>

			<div className={styles.bottomWrapper}>
				<div className={styles.bottom}>
					<button
						className={[styles.tryBtn].join(' ')}
						onClick={() => {
							setLoginPopupOpen(true);
							sendEvent('New Landing / Click Try button', {
								Location: '1st screen',
							});
							mixPanelService.sendEvent(
								{
									event: 'Landing / Click Try Button',
									properties: {
										Location: '1st screen',
									},
								},
								account?.userData?.steamAccountId || null
							);
						}}
					>
						{intl.formatMessage({ id: 'videolanding.intro.button' })}
					</button>
				</div>

				<AnchorLink href="#presentation" className={styles.scrollBtn}>
					<img src={arrowDown} className={styles.scrollBtnIcon} alt="arrow down" />
				</AnchorLink>
			</div>

			<LoginPopup isOpen={isLoginPopupOpen} onClose={() => setLoginPopupOpen(false)} />
		</section>
	);
};

const mapStateToProps = (state) => ({
	isLoginPopupOpen: state.loginPopupReducer.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
	setLoginPopupOpen: (payload) => dispatch(setLoginPopupOpen(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Intro);
